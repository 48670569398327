<template>
  <div
    class="main-wrap"
    :class="{ logged: isSearchGroupAccess }"
  >
    <!-- SLIDER -->
    <div class="m-container-offset">
      <section class="section__SliderMainPageFirst">
        <SliderMainPageFirst />
      </section>
    </div>

    <div class="m-container-offset">
      <!-- FEATURES: Делаем закупки быстрее и проще-->
      <section class="section__CompanyBenefits">
        <CompanyBenefits />
      </section>

      <section class="section__BannerDark">
        <BannerDark
          title="Главное о возможностях маркетплейса за 3 минуты"
          text=" Посмотрите короткую видеопрезентацию сервиса — это самый быстрый способ познакомиться с Максмарт"
          :btn="{
            label: 'Смотреть видеопрезентацию',
            theme: MM.EButtonThemes.Secondary,
            iconRight: MM.EIconPath.ActionVideoGradientSvg,
            iconFillDisabled: true,
            action: onShowVideoAboutModal,
          }"
          :img="{
            src: '/images/main/video-img.png',
            srcset: '/images/main/video-img-360.png 767w, /images/main/video-img.png 1000w',
            sources: [{ media: '(max-width: 767px)', src: '/images/main/video-img-360.png' }],
            action: onShowVideoAboutModal,
          }"
        />
      </section>

      <!-- Покупателям -->
      <section class="section__consumers">
        <ClientsBenefits :data="consumers" />
      </section>

      <!-- Поставщикам -->
      <section class="section__suppliers">
        <ClientsBenefits :data="suppliers" />
      </section>

      <!-- Каталог товаров -->
      <section class="mm-main__catalog">
        <CatalogCategoriesPromo />
      </section>

      <!-- Бренды на маркетплейсе (попросили пока что скрыть, может вернём)-->
      <section
        v-if="false"
        class="section__BrandsCarousel"
      >
        <BrandsCarousel />
      </section>
    </div>

    <div class="m-container">
      <section class="section__CompanyAwards">
        <!-- Максмарт высоко оценивается в профессиональном комьюнити -->
        <CompanyAwards />
      </section>

      <!-- <div class="m-container-offset"> -->
      <!-- Сеть распределительных центров Максмарт -->
      <section class="section__DistributionCentersMap">
        <DistributionCentersMap />
      </section>

      <!-- Новости Максмарт -->
      <section
        v-if="news?.items?.length"
        class="mm-main__news"
      >
        <div class="news__title text-m32">
          Новости Максмарт

          <MButton
            :type="MM.EButtonTypes.Text"
            :theme="MM.EButtonThemes.Primary"
            :icon-right="MM.EIconPath.NavigationMainArrowSvg"
            @click="onGoToAllNews"
          >
            Все новости
          </MButton>
        </div>
        <div class="news__list">
          <NewsCard
            v-for="(newsItem, index) in news?.items"
            :key="index"
            :data="newsItem"
          />
        </div>
      </section>
      <section class="section__AboutCompany">
        <AboutCompany />
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VideoAboutModal from 'components/modals/VideoAboutModal.vue';
import ModalManager from 'shared/services/modalManager.service';
import { useSearchGroupAccess } from 'composables/useGroup';
import 'vue3-carousel/dist/carousel.css';

import { NewsApiService } from 'services/api/newsApi.service';
import { NEWS_SECTION_ALL_ID } from 'constants/pages/news.const';
import { IStrapiNewsListResponse } from 'models/strapi/strapiNews.model';
import { IBasePagedResponse } from 'shared/models/baseApi.model';
import Loader from 'shared/utils/loaderHelper.util';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { useRedirectMirror } from 'composables/useRedirectMirror';

import SliderMainPageFirst from '/components/sliders/SliderMainPageFirst/SliderMainPageFirst.vue';
import CatalogCategoriesPromo from '/components/catalog/CatalogCategoriesPromo/CatalogCategoriesPromo.vue';
import NewsCard from '/components/news/NewsCard.vue';
import ClientsBenefits from '/components/static/ClientsBenefits.vue';
import CompanyBenefits from '/components/static/CompanyBenefits.vue';
import CompanyAwards from '/components/static/CompanyAwards.vue';
import DistributionCentersMap from '/components/static/DistributionCentersMap/DistributionCentersMap.vue';
import AboutCompany from 'components/static/AboutCompany.vue';
import BannerDark from 'components/static/BannerDark.vue';
import BrandsCarousel from '/components/static/BrandsCarousel.vue';
import { MM } from '~/types';
import { ELayout } from '~/enums/layouts.enum';
import { EClientApprovalType } from '~/enums/client/clientApprovalType.enum';
import { useHeaderStore } from 'store/Header.store';

// для новой шапки
// definePageMeta({
//   layout: ELayout.DefaultNew,
// });

onMounted(()=>{
  useHeaderStore().theme.isDark = false;
});

onUnmounted(()=>{
  useHeaderStore().theme.isDark = false;
});

definePageMeta({
  layout: ELayout.TransparentHead,
});

await useRedirectMirror();

useHead({
  title: 'B2B электронная торговая площадка, Б2Б маркетплейс быстрых оптовых закупок - Максмарт',
  meta: [
    { name: 'google-site-verification', content: useRuntimeConfig()?.public?.googleSiteVerificationToken },
    { name: 'yandex-verification', content: useRuntimeConfig()?.public?.yandexVerificationKey },
    {
      name: 'description',
      content:
        'Максмарт - B2B электронная торговая площадка, маркетплейс быстрых закупок. Оптимизация закупок для крупнейших промышленных компаний, снижение стоимости закупок, широкий ассортимент, логистика до базиса клиента.',
    },
  ],
});

const isSearchGroupAccess = useSearchGroupAccess();
useShowRedirectNotification();

const videoAboutModalName = 'videoAboutModal';
const modalManager = process.client ? inject<ModalManager>(ModalManager.getServiceName()) : undefined;
const isMounted = ref<boolean>(false);
const news = ref<IBasePagedResponse<IStrapiNewsListResponse>>();

const isNewsLoading = Loader.getReactiveInstance();

const { onSupplierRegistration } = useSupplierRegistration();
const { onCLickClientApprovalModal } = useClientApproval();
function onClientCreateClick() {
  onCLickClientApprovalModal(EClientApprovalType.Client);
}

function onShowVideoAboutModal(): void {
  modalManager?.show({
    component: VideoAboutModal,
    bind: {
      name: videoAboutModalName,
    },
    on: {
      close() {
        modalManager.hide(videoAboutModalName);
      },
      'update:modelValue'() {
        modalManager.hide(videoAboutModalName);
      },
    },
  });
}

function onGoToAllNews() {
  navigateTo('/news/');
}

async function loadNews() {
  try {
    isNewsLoading.activate();
    news.value = await NewsApiService.getNews({
      pageSize: 4,
      page: 1,
      sectionId: NEWS_SECTION_ALL_ID,
      mainPage: true,
    });
    isNewsLoading.deactivate();
  } catch (error) {
    clientSentry.captureException(error);
  }
}

onMounted(async () => {
  isMounted.value = true;
  await loadNews();
});

const consumers = {
  title: 'Покупателям',
  text: 'Предлагаем уникальный набор сервисов',

  list: [
    {
      title: 'Автоматизированные закупки из ERP',
      text: 'Заказы формируются на основе потребности благодаря бесшовной интеграции с ERP-системой покупателя',
      img: '/images/buyer-features-icon-1v2.png',
    },
    {
      title: 'Контролируемая доставка до базиса получателя',
      text: 'Максмарт осуществляет доставку заказов даже в отдаленные регионы',
      img: '/images/buyer-features-icon-4v2.png',
    },
    {
      title: 'Индивидуальные цены от поставщиков',
      text: 'Возможность закупки как по индивидуальным ценам от конкретных поставщиков, так и по всем предложениям на маркетплейсе',
      img: '/images/icon-4v2.png',
    },
    {
      title: 'Электронный документооборот',
      text: 'Экономия времени и денег на сопровождение процесса закупки',
      img: '/images/icon-2v2.png',
    },
    {
      title: 'Консалтинг и оптимизация процессов',
      text: 'Не тратьте время и деньги на согласование документов, сопоставление номенклатур и проверку поставщиков. Делегируйте это нам — покупайте быстрее',
      img: '/images/icon-1@2x.png',
    },
  ],
  footerTitle: 'С нами работают:',
  brands: Array.from({ length: 12 }, (_, i) => ({
    img: `/images/consumer-brands/consumer-brand-${i + 1}.png`,
  })),
  action: {
    label: 'Стать покупателем',
    onClick: () => onClientCreateClick(),
  },
};

const suppliers = {
  title: 'Поставщикам',
  text: 'Упрощаем работу с B2B-закупками',
  list: [
    {
      title: 'Регулярные заказы от корпоративных клиентов',
      text: 'Прямой выход на закупки крупнейших компаний',
      img: '/images/seller-feature-icon-1.png',
    },
    {
      title: 'Простая логистика',
      text: 'Доставляйте товары в распределительные центры маркетплейса. Консолидацию заказов и их доставку в удаленные базисы Максмарт берет на себя',
      img: '/images/icon-6v2.png',
    },
    {
      title: 'Отсутствие тендерных процедур',
      text: 'Единожды пройденная квалификация открывает доступ к заказам всех покупателей маркетплейса',
      img: '/images/seller-feature-icon-1.png',
    },
    {
      title: 'Помощь в создании цифрового каталога',
      text: 'Поможем создать электронный каталог ваших товаров, сопоставим ассортимент с номенклатурой покупателя и разместим его на маркетплейсе',
      img: '/images/seller-feature-icon-1.png',
    },
    {
      title: 'Электронный документооборот',
      text: 'Максмарт является единым контрагентом для всех заказов, что сокращает затраты на сопровождение заказа',
      img: '/images/icon-2v2.png',
    },
  ],
  footerTitle: 'С нами работают:',
  brands: Array.from({ length: 34 }, (_, i) => ({
    img: `/images/supplier-brands/supplier-brand-${i + 1}.png`,
  })), // бренд один попросили скрыть
  action: {
    label: 'Стать поставщиком',
    onClick: () => onSupplierRegistration(),
  },
};
</script>

<style scoped lang="scss">
@import 'shared/assets/styles/base/common/variables';

.main-wrap {
  // margin-top: 8px; // для новой шапки
}

.section {
  &__SliderMainPageFirst,
  &__CompanyBenefits {
    margin-bottom: 8px;
  }
  &__BannerDark,
  &__BrandsCarousel,
  &__consumers,
  &__suppliers,
  &__CompanyAwards,
  &__DistributionCentersMap,
  &__AboutCompany {
    margin-bottom: 80px;
  }

  @include maxw(1599) {
    &__BannerDark,
    &__BrandsCarousel,
    &__consumers,
    &__suppliers,
    &__CompanyAwards,
    &__DistributionCentersMap,
    &__AboutCompany {
      margin-bottom: 60px;
    }
  }

  @include maxw(1279) {
    &__BannerDark,
    &__BrandsCarousel,
    &__consumers,
    &__suppliers,
    &__CompanyAwards,
    &__DistributionCentersMap,
    &__AboutCompany {
      margin-bottom: 48px;
    }
  }
}

.mm-main {
  &__news {
    margin-bottom: 120px;

    .news__title {
      margin-bottom: 32px;
      color: $text-black;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .news__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 32px;
    }
  }
}

@media only screen and (max-width: 1599px) {
  .mm-main {
    &__news {
      margin-bottom: 80px;

      .news__item {
        .item__logo {
          height: 195px;
          max-width: 286px;
          transform: rotate(0);

          img {
            transform: rotate(0);
            height: 195px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-main {
    &__news {
      margin-bottom: 48px;
      margin-left: 4px;
      margin-right: 4px;

      .news__title {
        margin-bottom: 24px;
      }

      .news__list {
        grid-gap: 40px;
        grid-template-columns: repeat(2, 1fr);
      }

      .news__item {
        flex: 0 0 calc(50% - 8px);
        max-width: 344px;

        .item__logo {
          height: 233px;
          margin-bottom: 16px;
          max-width: 100%;

          img {
            height: 233px;
          }
        }

        .item__title {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
        }

        .item__name {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-main {
    &__news {
      .news__title {
        margin-bottom: 24px;
        flex-wrap: wrap;
        gap: 16px;

        .btn.btn-text {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .news__list {
        grid-gap: 8px;
      }

      .news__item {
        flex: 0 0 calc(50% - 8px);
        max-width: 156px;

        &:first-child {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        &:nth-child(2) {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        .item__title {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }

        .item__logo {
          height: 105px;
          margin-bottom: 12px;

          img {
            height: 105px;
            border-radius: 40px 0;
          }

          &.no-photo {
            border-radius: 40px 0;
          }
        }
      }
    }
  }
}
</style>
